import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RefreshTokeReq } from '../models/refresh-token-req.model';
import { AuthResponse } from './auth-response.model';
import { JWT } from './jwt.model';

const MINUTES_UNTIL_AUTO_LOGOUT = 60; //IN MINS
const CHECK_INTERVAL = 15000; //IN millisec
const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

type ResetPasswordPayload = {
  password: string;
  passwordConfirmation: string;
};

type AuthData = {
  userId: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  isAuthenticated: boolean;
  isDexcomAdmin: boolean;
};

@Injectable({ providedIn: 'root' })
export class AuthService implements AuthData {
  baseUrl = `${environment.apiUrl}/devportal/auth`;

  isAuthenticated = false;
  userId = '';
  email = '';
  username = '';
  firstName = '';
  lastName = '';
  isDexcomAdmin = false;

  intervalId?: NodeJS.Timeout;
  lastActionDate = new Date();
  tokenExpirationDate: Date = new Date(0);

  constructor(private http: HttpClient, private router: Router) {}

  get accessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  set accessToken(token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token ?? '');
  }

  login(username: string, password: string) {
    return this.http.post<AuthResponse>(`${this.baseUrl}/login`, { username, password }).pipe(
      map((res: AuthResponse) => {
        this.autoLogOutcheck();
        this.initActivityListeners();
        this.initInterval();
        this.resetLastActionTimestamp();

        this.accessToken = res.access_token;

        const jwt = this.parseAuthToken(res.access_token);
        this.setAuthData({
          email: jwt.email,
          username: jwt.preferred_username,
          firstName: jwt.given_name,
          lastName: jwt.family_name,
          userId: jwt.sub,
          isAuthenticated: true,
          isDexcomAdmin: jwt.realm_access.roles.includes('dexcom_admin'),
        });

        this.tokenExpirationDate = new Date(jwt.exp * 1000);
      })
    );
  }

  logout() {
    return this.http.post<void>(`${this.baseUrl}/logout`, { access_token: this.accessToken }).pipe(
      finalize(() => {
        this.router.navigate(['./']);
        this.clearSession();
      })
    );
  }

  parseAuthToken(token: string): JWT {
    const tokenBody = token.split('.')[1];
    const tokenString = atob(tokenBody);
    return JSON.parse(tokenString);
  }

  setAuthDataFromJWT(jwt: JWT) {
    this.isAuthenticated = true;
    this.userId = jwt.sub;
    this.email = jwt.email;
    this.username = jwt.preferred_username;
    this.firstName = jwt.given_name;
    this.lastName = jwt.family_name;
    this.tokenExpirationDate = new Date(jwt.exp * 1000);
    this.isDexcomAdmin = jwt.realm_access.roles.includes('dexcom_admin');
  }

  setAuthData(authData: Partial<AuthData>) {
    // need to use nullish coalescing here, because we want the property to be reassigned if an empty string or false is passed
    this.email = authData.email ?? this.email;
    this.username = authData.username ?? this.username;
    this.firstName = authData.firstName ?? this.firstName;
    this.lastName = authData.lastName ?? this.lastName;
    this.userId = authData.userId ?? this.userId;
    this.isAuthenticated = authData.isAuthenticated ?? this.isAuthenticated;
    this.isDexcomAdmin = authData.isDexcomAdmin ?? this.isDexcomAdmin;
  }

  autoLogin() {
    const token = this.accessToken;
    if (!token) return;

    const jwt = this.parseAuthToken(token);
    this.setAuthDataFromJWT(jwt);

    this.autoLogOutcheck();
    this.initActivityListeners();
    this.initInterval();
    this.resetLastActionTimestamp();
  }

  register(email: string, username: string, password: string, firstName: string, lastName: string) {
    return this.http.post<void>(`${this.baseUrl}/register`, {
      email,
      username,
      password,
      firstName,
      lastName,
    });
  }

  assessRecaptchaToken(token: string) {
    return this.http.post(`${this.baseUrl}/recaptcha`, { token });
  }

  getRefreshToken(tokenpayload: RefreshTokeReq) {
    return this.http.post<AuthResponse>(`${this.baseUrl}/refreshToken`, tokenpayload);
  }

  forgotPassword(email = '', username = '') {
    return this.http.post<void>(`${this.baseUrl}/forgot-password?email=${email}&username=${username}`, null);
  }

  resetPassword(token: string, payload: ResetPasswordPayload) {
    return this.http.post<void>(`${this.baseUrl}/reset-password?token=${token}`, payload);
  }

  updatePassword(password: string, newPassword: string): Observable<void> {
    const body = { userName: this.username, password, newPassword };
    return this.http.put<void>(`${this.baseUrl}/update-password`, body);
  }

  clearSession() {
    this.setAuthData({
      userId: '',
      email: '',
      username: '',
      firstName: '',
      lastName: '',
      isAuthenticated: false,
      isDexcomAdmin: false,
    });
    this.accessToken = '';
    this.tokenExpirationDate = new Date(0);
    if (this.intervalId) clearInterval(this.intervalId);
    this.removeActivityListeners();
  }

  // Auto logout listners

  initActivityListeners() {
    document.body.addEventListener('click', this.resetLastActionTimestamp);
    document.body.addEventListener('mouseover', this.resetLastActionTimestamp);
    document.body.addEventListener('mouseout', this.resetLastActionTimestamp);
    document.body.addEventListener('keydown', this.resetLastActionTimestamp);
    document.body.addEventListener('keyup', this.resetLastActionTimestamp);
    document.body.addEventListener('keypress', this.resetLastActionTimestamp);
  }

  removeActivityListeners() {
    document.body.removeEventListener('click', this.resetLastActionTimestamp);
    document.body.removeEventListener('mouseover', this.resetLastActionTimestamp);
    document.body.removeEventListener('mouseout', this.resetLastActionTimestamp);
    document.body.removeEventListener('keydown', this.resetLastActionTimestamp);
    document.body.removeEventListener('keyup', this.resetLastActionTimestamp);
    document.body.removeEventListener('keypress', this.resetLastActionTimestamp);
  }

  resetLastActionTimestamp() {
    this.lastActionDate = new Date();
  }

  initInterval() {
    this.intervalId = setInterval(() => {
      this.autoLogOutcheck();
    }, CHECK_INTERVAL);
  }

  autoLogOutcheck() {
    const logoutDate = this.lastActionDate;
    logoutDate.setMinutes(this.lastActionDate.getMinutes() + MINUTES_UNTIL_AUTO_LOGOUT);

    if (logoutDate < new Date()) {
      this.logout().subscribe(() => this.router.navigate(['/']));
    }
  }
}
