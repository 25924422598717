import { Component } from '@angular/core';
import $ from 'jquery';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-nav-bar-auth-button',
  templateUrl: './nav-bar-auth-button.component.html',
  styleUrls: ['./nav-bar-auth-button.component.css'],
})
export class NavBarAuthButtonComponent {
  constructor(public authService: AuthService) {}

  toggleLogIn(event: MouseEvent) {
    const dialog = document.getElementById('login') as HTMLInputElement;
    if (dialog.style.display === 'block') {
      if (!$(event.target as HTMLDivElement).closest('#login .modal-content').length) {
        dialog.style.display = '';
        dialog.classList.remove('in');
      }
    } else {
      dialog.style.display = 'block';
      dialog.classList.add('in');
    }
  }

  toggleUserInfo(): void {
    const dialog = document.getElementById('user') as HTMLInputElement;
    if (dialog.classList.contains('open')) {
      dialog.classList.remove('open');
    } else {
      dialog.classList.add('open');
    }
  }

  logout() {
    this.authService.logout().subscribe();
    this.toggleUserInfo();
  }
}
