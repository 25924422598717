import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthResponse } from '../services/auth-response.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<AuthResponse>, next: HttpHandler) {
    if (req.url.includes('refreshToken')) {
      return next.handle(req);
    }

    const accessToken = this.authService.accessToken;
    if (this.authService.isAuthenticated && accessToken) {
      if (new Date() < this.authService.tokenExpirationDate) {
        const newReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
        });
        return next.handle(newReq);
      }

      return this.authService.getRefreshToken({ access_token: accessToken }).pipe(
        catchError((err: any) => {
          if (err.status == 401) {
            this.authService.logout().subscribe();
          }
          return throwError(() => err);
        }),
        switchMap((newTokenPayload: AuthResponse) => {
          const jwt = this.authService.parseAuthToken(newTokenPayload.access_token);
          this.authService.accessToken = newTokenPayload.access_token;
          this.authService.tokenExpirationDate = new Date(jwt.exp * 1000);
          this.authService.setAuthData({
            email: jwt.email,
            username: jwt.preferred_username,
            firstName: jwt.given_name,
            lastName: jwt.family_name,
            userId: jwt.sub,
            isAuthenticated: true,
            isDexcomAdmin: jwt.realm_access.roles.includes('dexcom_admin'),
          });

          const newReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${newTokenPayload.access_token}`),
          });
          return next.handle(newReq);
        })
      );
    }

    return next.handle(req);
  }
}
