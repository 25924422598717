import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { ConsentParams } from '../consentParams';
import { ConsentParamService } from '../consentParamService';

@Component({
  selector: 'app-sandbox-consent',
  templateUrl: './sandbox-consent.component.html',
  styleUrls: ['./sandbox-consent.component.css'],
})
export class SandboxConsentComponent implements OnInit {
  appNameAvailable = false;
  registerDate = new Date().toLocaleString();
  appName = '';
  url = '';
  username = '';
  constructor(
    public router: Router,
    private service: ApiService,
    private consentParamService: ConsentParamService
  ) {}

  ngOnInit() {
    const clientId = this.consentParamService.getConsentBody().getClientId();
    if (clientId) {
      this.service.getAppName(clientId).subscribe(
        (res) => {
          this.appName = res.appName;
          this.appNameAvailable = true;
        },
        (err) => {
          this.router.navigate(['/sandbox-error']);
        }
      );
    }
  }

  authorize() {
    const consentBody = this.consentParamService.getConsentBody();
    consentBody.setScope(['egv', 'calibrations', 'devices', 'dataRange', 'events', 'statistics']);
    const state = consentBody.getState();

    this.service.setConsents(consentBody).subscribe(
      (res) => {
        if (state != '') {
          this.url = '?code=' + res.authCode + '&state=' + state;
        } else {
          this.url = '?code=' + res.authCode;
        }

        window.location.assign(
          decodeURIComponent(consentBody.getRedirectUrl().toString()) + this.url
        );
      },
      (err) => {
        this.router.navigate(['/sandbox-error']);
      }
    );
  }

  decline() {
    const consentBody: ConsentParams = this.consentParamService.getConsentBody();
    console.log(consentBody.getRedirectUrl());
    window.location.assign(
      decodeURIComponent(consentBody.getRedirectUrl().toString()) + '?error=access_denied'
    );
  }
}
