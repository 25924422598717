import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    if (this.authService.isAuthenticated) {
      return true;
    }
    this.router.navigate(['./']);
    const dialog = document.getElementById('login') as HTMLInputElement;
    if (dialog.style.display === 'block') {
      dialog.style.display = '';
      dialog.classList.remove('in');
    } else {
      dialog.style.display = 'block';
      dialog.classList.add('in');
    }
    return false;
  }
}
