<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML+RDFa 1.0//EN" "http://www.w3.org/MarkUp/DTD/xhtml-rdfa-1.dtd">
<html
  lang="en"
  dir="ltr"
  prefix="content: http://purl.org/rss/1.0/modules/content/ dc: http://purl.org/dc/terms/ foaf: http://xmlns.com/foaf/0.1/ og: http://ogp.me/ns# rdfs: http://www.w3.org/2000/01/rdf-schema# sioc: http://rdfs.org/sioc/ns# sioct: http://rdfs.org/sioc/types# skos: http://www.w3.org/2004/02/skos/core# xsd: http://www.w3.org/2001/XMLSchema#"
  class="js wf-proximanova-n7-active wf-proximanova-i7-active wf-proximanova-n6-active wf-proximanova-n4-active wf-proximanova-i4-active wf-proximanovacondensed-n7-active wf-proximanovacondensed-i7-active wf-proximanovacondensed-n4-active wf-proximanovacondensed-i4-active wf-proximanovaextracondensed-i7-active wf-proximanovaextracondensed-n7-active wf-proximanovaextracondensed-i4-active wf-proximanovaextracondensed-n4-active wf-museo-n3-active wf-museo-n7-active wf-museo-i3-active wf-museo-i7-active wf-active"
>
  <head profile="http://www.w3.org/1999/xhtml/vocab">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />

    <meta http-equiv="X-UA-Compatible" content="IE=Edge,chrome=1" />

    <meta name="Generator" content="Drupal 7 (http://drupal.org)" />
    <link rel="canonical" href="https://developer.dexcom.com/home" />
    <link rel="shortlink" href="https://developer.dexcom.com/node/1" />
    <link rel="shortcut icon" href="../assets/image/favicon.ico" type="image/vnd.microsoft.icon" />
    <title>Dexcom API | home</title>
    <style>
      @import url('../assets/css/system.base.css');
    </style>
    <style>
      @import url('../assets/css/jquery.ui.core.min.css');
      @import url('../assets/css/jquery.ui.theme.min.css');
      @import url('../assets/css/bootstrap.min.css');
    </style>

    <style>
      @import url('../assets/css/date.css');
      @import url('../assets/css/field.css');
      @import url('../assets/css/logintoboggan.css');
      @import url('../assets/css/node.css');
      @import url('../assets/css/views.css');
      @import url('../assets/css/ckeditor.css');
    </style>
    <style>
      @import url('../assets/css/ctools.css');
      @import url('../assets/css/modal.css');
      @import url('../assets/css/modal_forms_popup.css');
    </style>
    <!-- <link
    type="text/css"
    rel="stylesheet"
    href="../assets/css/jquery.qtip.min.css"
    media="all"
  /> -->
    <style>
      @import url('../assets/css/qtip.css');
      @import url('../assets/css/smartdocs.css');
      @import url('../assets/css/honeypot.css');
      @import url('../assets/css/webform.css');
    </style>
    <style>
      @import url('../assets/css/style.css');
      @import url('../assets/css/dexcom-apigee.normalize.css');
      @import url('../assets/css/dexcom-apigee.styles.css');
      @import url('../assets/css/dexcom-apigee.hacks.css');
    </style>

    <!--[if lte IE 8]>
      <style>
        @import url('https://developer.dexcom.com/sites/all/themes/dexcom_apigee/css/dexcom-apigee.no-query.css?pvugeu');
      </style>
    <![endif]-->
    <!-- HTML5 element support for IE6-8 -->
    <!--[if lt IE 9]>
      <script src="//html5shiv.googlecode.com/svn/trunk/html5.js"></script>
    <![endif]-->
    <script async="" src="../assets/js/analytics.js"></script>
    <script src="../assets/js/jquery.min.js"></script>
    <script>
      window.jQuery ||
        document.write(
          "<script src='/profiles/apigee/modules/contrib/jquery_update/replace/jquery/2.1/jquery.min.js'>\x3C/script>"
        );
    </script>
    <script>
      jQuery.migrateMute = true;
      jQuery.migrateTrace = false;
    </script>
    <script src="../assets/js/jquery-migrate.min.js"></script>
    <script src="../assets/js/jquery-extend-3.4.0.js"></script>
    <script src="../assets/js/jquery.once.js"></script>
    <script src="../assets/js/drupal.js"></script>
    <script src="../assets/js/jquery-ui.min.js"></script>
    <script>
      window.jQuery.ui ||
        document.write(
          "<script src='/profiles/apigee/modules/contrib/jquery_update/replace/ui/ui/minified/jquery-ui.min.js'>\x3C/script>"
        );
    </script>
    <script src="../assets/js/jquery.cookie.js"></script>
    <script src="../assets/js/jquery.form.min.js"></script>
    <script src="../assets/js/bootstrap.min.js"></script>
    <script src="../assets/js/ajax.js"></script>
    <script src="../assets/js/jquery_update.js"></script>
    <script src="../assets/js/jquery_ajax_load.js"></script>
    <script src="../assets/js/_progress.js"></script>
    <script src="../assets/js/modal.js"></script>
    <script src="../assets/js/modal_forms_popup.js"></script>
    <!-- <script src="../assets/js/jquery.qtip.min.js"></script> -->
    <script src="../assets/js/twitter_bootstrap_modal.js"></script>
    <script src="../assets/js/qtip_webform.js"></script>
    <script src="../assets/js/webform.js"></script>
    <script src="../assets/js/raphael.js"></script>
    <script src="../assets/js/googleanalytics.js"></script>
    <script src="../assets/js/drupal_animations.js"></script>
    <script>
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
      ga('create', 'UA-91462081-2', { cookieDomain: '.developer.dexcom.com' });
      ga('set', 'anonymizeIp', true);
      ga('send', 'pageview');
    </script>
    <script src="../assets/js/dexcom_front_blocks.js"></script>
    <script src="../assets/js/dexcom_user_forms.js"></script>
    <script src="../assets/js/dexcom_search.js"></script>
    <script src="../assets/js/bootstrap_modal_forms.forms.js"></script>
    <script src="../assets/js/qtip.js"></script>
    <script src="../assets/js/global.js"></script>
    <script src="../assets/js/dexcom_apigee.js"></script>
    <script src="../assets/js/ajax(1).js"></script>
    <script src="../assets/js/modal(1).js"></script>
    <script>
      jQuery.extend(Drupal.settings, {
        basePath: '\/',
        pathPrefix: '',
        ajaxPageState: {
          theme: 'dexcom_apigee',
          theme_token: '5lG6unEMCBliZdo28ifTXNyxECPXQi5tnJ60_SCf7lQ',
          jquery_version: '2.1',
          js: {
            'profiles\/apigee\/themes\/bootstrap\/js\/bootstrap.js': 1,
            '\/\/ajax.googleapis.com\/ajax\/libs\/jquery\/2.1.4\/jquery.min.js': 1,
            0: 1,
            1: 1,
            'profiles\/apigee\/modules\/contrib\/jquery_update\/replace\/jquery-migrate\/1\/jquery-migrate.min.js': 1,
            'misc\/jquery-extend-3.4.0.js': 1,
            'misc\/jquery.once.js': 1,
            'misc\/drupal.js': 1,
            '\/\/ajax.googleapis.com\/ajax\/libs\/jqueryui\/1.10.2\/jquery-ui.min.js': 1,
            2: 1,
            'profiles\/apigee\/modules\/contrib\/jquery_update\/replace\/ui\/external\/jquery.cookie.js': 1,
            'profiles\/apigee\/modules\/contrib\/jquery_update\/replace\/jquery.form\/4\/jquery.form.min.js': 1,
            'profiles\/apigee\/themes\/apigee_responsive\/js\/bootstrap.min.js': 1,
            'misc\/ajax.js': 1,
            'profiles\/apigee\/modules\/contrib\/jquery_update\/js\/jquery_update.js': 1,
            'sites\/all\/modules\/contrib\/jquery_ajax_load\/jquery_ajax_load.js': 1,
            'profiles\/apigee\/themes\/bootstrap\/js\/misc\/_progress.js': 1,
            'profiles\/apigee\/modules\/contrib\/ctools\/js\/modal.js': 1,
            'sites\/all\/modules\/modal_forms\/js\/modal_forms_popup.js': 1,
            '\/\/cdn.jsdelivr.net\/qtip2\/3.0.3\/jquery.qtip.min.js': 1,
            'sites\/all\/modules\/contrib\/twitter_bootstrap_modal\/twitter_bootstrap_modal.js': 1,
            'sites\/all\/modules\/qtip\/modules\/qtip_webform\/js\/qtip_webform.js': 1,
            'profiles\/apigee\/modules\/contrib\/webform\/js\/webform.js': 1,
            '.\/Dexcom API _ home_files\/raphael.js': 1,
            'sites\/all\/modules\/google_analytics\/googleanalytics.js': 1,
            '.\/Dexcom API _ home_files\/drupal_animations.js': 1,
            3: 1,
            'sites\/all\/modules\/features\/dexcom_front_blocks\/js\/dexcom_front_blocks.js': 1,
            'sites\/all\/modules\/custom\/dexcom_user_forms\/js\/dexcom_user_forms.js': 1,
            'sites\/all\/modules\/features\/dexcom_search\/js\/dexcom_search.js': 1,
            'profiles\/apigee\/modules\/contrib\/bootstrap_modal_forms\/js\/bootstrap_modal_forms.forms.js': 1,
            'sites\/all\/modules\/qtip\/js\/qtip.js': 1,
            'profiles\/apigee\/themes\/apigee_responsive\/js\/global.js': 1,
            'sites\/all\/themes\/dexcom_apigee\/js\/dexcom_apigee.js': 1,
            'profiles\/apigee\/themes\/bootstrap\/js\/misc\/ajax.js': 1,
            'profiles\/apigee\/themes\/bootstrap\/js\/modules\/ctools\/js\/modal.js': 1,
          },
          css: {
            'modules\/system\/system.base.css': 1,
            'misc\/ui\/jquery.ui.core.css': 1,
            'misc\/ui\/jquery.ui.theme.css': 1,
            'profiles\/apigee\/themes\/bootstrap\/css\/overrides.css': 1,
            '..\/assets\/css\/bootstrap.min.css': 1,
            'profiles\/apigee\/modules\/contrib\/date\/date_api\/date.css': 1,
            'modules\/field\/theme\/field.css': 1,
            'profiles\/apigee\/modules\/contrib\/logintoboggan\/logintoboggan.css': 1,
            'modules\/node\/node.css': 1,
            'profiles\/apigee\/modules\/contrib\/views\/css\/views.css': 1,
            'profiles\/apigee\/modules\/contrib\/ckeditor\/css\/ckeditor.css': 1,
            'profiles\/apigee\/modules\/contrib\/ctools\/css\/ctools.css': 1,
            'profiles\/apigee\/modules\/contrib\/ctools\/css\/modal.css': 1,
            'sites\/all\/modules\/modal_forms\/css\/modal_forms_popup.css': 1,
            '\/\/cdn.jsdelivr.net\/qtip2\/3.0.3\/jquery.qtip.min.css': 1,
            'sites\/all\/modules\/qtip\/css\/qtip.css': 1,
            'profiles\/apigee\/modules\/custom\/devconnect\/smartdocs\/css\/smartdocs.css': 1,
            'public:\/\/honeypot\/honeypot.css': 1,
            'profiles\/apigee\/modules\/contrib\/webform\/css\/webform.css': 1,
            'sites\/all\/themes\/dexcom_apigee\/css\/style.css': 1,
            'sites\/all\/themes\/dexcom_apigee\/css\/dexcom-apigee.normalize.css': 1,
            'sites\/all\/themes\/dexcom_apigee\/css\/dexcom-apigee.styles.css': 1,
            'sites\/all\/themes\/dexcom_apigee\/css\/dexcom-apigee.hacks.css': 1,
            'sites\/all\/themes\/dexcom_apigee\/css\/dexcom-apigee.no-query.css': 1,
          },
        },
        jquery_ajax_load: {
          trigger: '.jquery_ajax_load',
          target: '#jquery_ajax_load_target',
          toggle: true,
          animation: true,
          base_path: '\/',
          module_path: 'sites\/all\/modules\/contrib\/jquery_ajax_load',
          site_name: 'Dexcom API',
          TBtrigger: '.twitter_bootstrap_modal_TB',
          TBmodaltrigger: '.bs_modal',
          TBmodule: 'sites\/all\/modules\/contrib\/twitter_bootstrap_modal',
          TBpath: '\/',
          TBname: 'Dexcom API',
        },
        CToolsModal: {
          loadingText: 'Loading...',
          closeText: 'Close Window',
          closeImage:
            '\u003Cimg typeof=\u0022foaf:Image\u0022 class=\u0022img-responsive\u0022 src=\u0022https:\/\/developer.dexcom.com\/profiles\/apigee\/modules\/contrib\/ctools\/images\/icon-close-window.png\u0022 alt=\u0022Close window\u0022 title=\u0022Close window\u0022 \/\u003E',
          throbber:
            '\u003Cimg typeof=\u0022foaf:Image\u0022 class=\u0022img-responsive\u0022 src=\u0022https:\/\/developer.dexcom.com\/profiles\/apigee\/modules\/contrib\/ctools\/images\/throbber.gif\u0022 alt=\u0022Loading\u0022 title=\u0022Loading...\u0022 \/\u003E',
        },
        'modal-popup-small': {
          modalSize: { type: 'fixed', width: 300, height: 300 },
          modalOptions: { opacity: 0.85, background: '#000' },
          animation: 'fadeIn',
          modalTheme: 'ModalFormsPopup',
          throbber:
            '\u003Cimg typeof=\u0022foaf:Image\u0022 class=\u0022img-responsive\u0022 src=\u0022https:\/\/developer.dexcom.com\/sites\/all\/modules\/modal_forms\/images\/loading_animation.gif\u0022 alt=\u0022Loading...\u0022 title=\u0022Loading\u0022 \/\u003E',
          closeText: 'Close',
        },
        'modal-popup-medium': {
          modalSize: { type: 'fixed', width: 550, height: 450 },
          modalOptions: { opacity: 0.85, background: '#000' },
          animation: 'fadeIn',
          modalTheme: 'ModalFormsPopup',
          throbber:
            '\u003Cimg typeof=\u0022foaf:Image\u0022 class=\u0022img-responsive\u0022 src=\u0022https:\/\/developer.dexcom.com\/sites\/all\/modules\/modal_forms\/images\/loading_animation.gif\u0022 alt=\u0022Loading...\u0022 title=\u0022Loading\u0022 \/\u003E',
          closeText: 'Close',
        },
        'modal-popup-large': {
          modalSize: { type: 'scale', width: 0.8, height: 0.8 },
          modalOptions: { opacity: 0.85, background: '#000' },
          animation: 'fadeIn',
          modalTheme: 'ModalFormsPopup',
          throbber:
            '\u003Cimg typeof=\u0022foaf:Image\u0022 class=\u0022img-responsive\u0022 src=\u0022https:\/\/developer.dexcom.com\/sites\/all\/modules\/modal_forms\/images\/loading_animation.gif\u0022 alt=\u0022Loading...\u0022 title=\u0022Loading\u0022 \/\u003E',
          closeText: 'Close',
        },
        instances:
          '{\u0022app_tip\u0022:{\u0022content\u0022:{\u0022text\u0022:\u0022\u0022},\u0022style\u0022:{\u0022tip\u0022:false,\u0022classes\u0022:\u0022\u0022},\u0022position\u0022:{\u0022at\u0022:\u0022bottom right\u0022,\u0022adjust\u0022:{\u0022method\u0022:\u0022\u0022},\u0022my\u0022:\u0022top left\u0022,\u0022viewport\u0022:false},\u0022show\u0022:{\u0022event\u0022:\u0022mouseenter \u0022},\u0022hide\u0022:{\u0022event\u0022:\u0022mouseleave \u0022}},\u0022default\u0022:{\u0022content\u0022:{\u0022text\u0022:\u0022\u0022},\u0022style\u0022:{\u0022tip\u0022:false,\u0022classes\u0022:\u0022\u0022},\u0022position\u0022:{\u0022at\u0022:\u0022bottom right\u0022,\u0022adjust\u0022:{\u0022method\u0022:\u0022\u0022},\u0022my\u0022:\u0022top left\u0022,\u0022viewport\u0022:false},\u0022show\u0022:{\u0022event\u0022:\u0022mouseenter \u0022},\u0022hide\u0022:{\u0022event\u0022:\u0022mouseleave \u0022}}}',
        qtipDebug: '{\u0022leaveElement\u0022:0}',
        urlIsAjaxTrusted: { '\/': true, '\/system\/ajax': true },
        qtip_webform: {
          configure: {
            instance: '',
            required: 0,
            required_instance: '',
            required_text: 'This field is required!',
          },
        },
        ajax: {
          'edit-search-term': {
            callback: 'dexcom_search_search_block_submit_callback',
            wrapper: 'results',
            keypress: true,
            progress: { type: 'none' },
            event: 'blur',
            url: '\/system\/ajax',
            submit: { _triggering_element_name: 'search_term' },
          },
        },
        googleanalytics: {
          trackOutbound: 1,
          trackMailto: 1,
          trackDownload: 1,
          trackDownloadExtensions:
            '7z|aac|arc|arj|asf|asx|avi|bin|csv|doc(x|m)?|dot(x|m)?|exe|flv|gif|gz|gzip|hqx|jar|jpe?g|js|mp(2|3|4|e?g)|mov(ie)?|msi|msp|pdf|phps|png|ppt(x|m)?|pot(x|m)?|pps(x|m)?|ppam|sld(x|m)?|thmx|qtm?|ra(m|r)?|sea|sit|tar|tgz|torrent|txt|wav|wma|wmv|wpd|xls(x|m|b)?|xlt(x|m)|xlam|xml|z|zip',
          trackDomainMode: 1,
        },
        scrolling: 0,
        dots_animation: {
          'cta-2-animation-canvas': {
            animation_settings: {
              canvas_height: 768,
              canvas_width: 1024,
              canvas_image_uri:
                'https:\/\/developer.dexcom.com\/sites\/default\/files\/styles\/cta_2_thumb\/module\/dexcom_front_blocks\/images\/cta_2.png',
              canvas_image_height: 439,
              canvas_image_width: 593,
              canvas_image_transform: 'm1 0 0 1 423 220',
              animation_start: 400,
              animation_stop: 768,
            },
          },
          'cta-3-animation-canvas': {
            animation_settings: {
              canvas_height: 768,
              canvas_width: 1024,
              canvas_image_uri:
                'https:\/\/developer.dexcom.com\/sites\/default\/files\/styles\/cta_3_thumb\/module\/dexcom_front_blocks\/images\/cta_3.png',
              canvas_image_height: 586,
              canvas_image_width: 322,
              canvas_image_transform: 'm1 0 0 1 60 128',
              animation_start: 400,
              animation_stop: 768,
            },
          },
        },
        bootstrap_modal_forms: {
          login: {
            identifier: 'login',
            url: 'user\/login',
            open_onload: false,
          },
          webform_client_form_49: {
            identifier: 'webform_client_form_49',
            url: 'content\/unsubscribe',
            open_onload: false,
          },
          dexcom_search_search_block_modal_form: {
            identifier: 'dexcom_search_search_block_modal_form',
            url: 'search-results',
            open_onload: false,
          },
          dexcom_user_forms_welcome_modal_form: {
            identifier: 'dexcom_user_forms_welcome_modal_form',
            url: 'welcome-modal',
            open_onload: false,
          },
        },
        bootstrap_modal_forms_current_path: 'node\/1',
        bootstrap: {
          anchorsFix: '0',
          anchorsSmoothScrolling: '0',
          formHasError: 1,
          popoverEnabled: 1,
          popoverOptions: {
            animation: 1,
            html: 0,
            placement: 'right',
            selector: '',
            trigger: 'click',
            triggerAutoclose: 1,
            title: '',
            content: '',
            delay: 0,
            container: 'body',
          },
          tooltipEnabled: 1,
          tooltipOptions: {
            animation: 1,
            html: 0,
            placement: 'top',
            selector: '',
            trigger: 'hover focus',
            delay: 0,
            container: 'body',
          },
        },
      });
    </script>
    <script src="../assets/js/smw0fmc.js"></script>
    <style type="text/css">
      .tk-proxima-nova {
        font-family: 'proxima-nova', sans-serif;
      }

      .tk-proxima-nova-condensed {
        font-family: 'proxima-nova-condensed', sans-serif;
      }

      .tk-proxima-nova-extra-condensed {
        font-family: 'proxima-nova-extra-condensed', sans-serif;
      }

      .tk-museo {
        font-family: 'museo', serif;
      }
    </style>
    <style type="text/css">
      @font-face {
        font-family: proxima-nova;
        src: url(https://use.typekit.net/af/925423/00000000000000003b9b038f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/925423/00000000000000003b9b038f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('woff'),
          url(https://use.typekit.net/af/925423/00000000000000003b9b038f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('opentype');
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: proxima-nova;
        src: url(https://use.typekit.net/af/cd78b3/00000000000000003b9b038e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/cd78b3/00000000000000003b9b038e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('woff'),
          url(https://use.typekit.net/af/cd78b3/00000000000000003b9b038e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('opentype');
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: proxima-nova;
        src: url(https://use.typekit.net/af/8e11d4/00000000000000003b9b038c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/8e11d4/00000000000000003b9b038c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3)
            format('woff'),
          url(https://use.typekit.net/af/8e11d4/00000000000000003b9b038c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3)
            format('opentype');
        font-weight: 600;
        font-style: normal;
      }

      @font-face {
        font-family: proxima-nova;
        src: url(https://use.typekit.net/af/219c30/00000000000000003b9b0389/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/219c30/00000000000000003b9b0389/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('woff'),
          url(https://use.typekit.net/af/219c30/00000000000000003b9b0389/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('opentype');
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: proxima-nova;
        src: url(https://use.typekit.net/af/0de7d4/00000000000000003b9b0388/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/0de7d4/00000000000000003b9b0388/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('woff'),
          url(https://use.typekit.net/af/0de7d4/00000000000000003b9b0388/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('opentype');
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: proxima-nova-condensed;
        src: url(https://use.typekit.net/af/dcf25d/00000000000000003b9b039f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/dcf25d/00000000000000003b9b039f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('woff'),
          url(https://use.typekit.net/af/dcf25d/00000000000000003b9b039f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('opentype');
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: proxima-nova-condensed;
        src: url(https://use.typekit.net/af/548ceb/00000000000000003b9b039e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/548ceb/00000000000000003b9b039e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('woff'),
          url(https://use.typekit.net/af/548ceb/00000000000000003b9b039e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('opentype');
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: proxima-nova-condensed;
        src: url(https://use.typekit.net/af/0b2482/00000000000000003b9b0398/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/0b2482/00000000000000003b9b0398/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('woff'),
          url(https://use.typekit.net/af/0b2482/00000000000000003b9b0398/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('opentype');
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: proxima-nova-condensed;
        src: url(https://use.typekit.net/af/711107/00000000000000003b9b0399/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/711107/00000000000000003b9b0399/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('woff'),
          url(https://use.typekit.net/af/711107/00000000000000003b9b0399/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('opentype');
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: proxima-nova-extra-condensed;
        src: url(https://use.typekit.net/af/77b8d8/00000000000000003b9b03ae/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/77b8d8/00000000000000003b9b03ae/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('woff'),
          url(https://use.typekit.net/af/77b8d8/00000000000000003b9b03ae/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('opentype');
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: proxima-nova-extra-condensed;
        src: url(https://use.typekit.net/af/cd1348/00000000000000003b9b03af/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/cd1348/00000000000000003b9b03af/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('woff'),
          url(https://use.typekit.net/af/cd1348/00000000000000003b9b03af/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('opentype');
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: proxima-nova-extra-condensed;
        src: url(https://use.typekit.net/af/784b7d/00000000000000003b9b03a9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/784b7d/00000000000000003b9b03a9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('woff'),
          url(https://use.typekit.net/af/784b7d/00000000000000003b9b03a9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3)
            format('opentype');
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: proxima-nova-extra-condensed;
        src: url(https://use.typekit.net/af/afe63a/00000000000000003b9b03a8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/afe63a/00000000000000003b9b03a8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('woff'),
          url(https://use.typekit.net/af/afe63a/00000000000000003b9b03a8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format('opentype');
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: museo;
        src: url(https://use.typekit.net/af/f7c91f/000000000000000000011b23/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/f7c91f/000000000000000000011b23/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format('woff'),
          url(https://use.typekit.net/af/f7c91f/000000000000000000011b23/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format('opentype');
        font-weight: 300;
        font-style: normal;
      }

      @font-face {
        font-family: museo;
        src: url(https://use.typekit.net/af/8a79e7/000000000000000000011b24/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/8a79e7/000000000000000000011b24/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('woff'),
          url(https://use.typekit.net/af/8a79e7/000000000000000000011b24/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format('opentype');
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: museo;
        src: url(https://use.typekit.net/af/9797cd/00000000000000000000ebe4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/9797cd/00000000000000000000ebe4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format('woff'),
          url(https://use.typekit.net/af/9797cd/00000000000000000000ebe4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3)
            format('opentype');
        font-weight: 300;
        font-style: italic;
      }

      @font-face {
        font-family: museo;
        src: url(https://use.typekit.net/af/beaa6e/00000000000000000000ebe6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('woff2'),
          url(https://use.typekit.net/af/beaa6e/00000000000000000000ebe6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('woff'),
          url(https://use.typekit.net/af/beaa6e/00000000000000000000ebe6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3)
            format('opentype');
        font-weight: 700;
        font-style: italic;
      }
    </style>
    <script>
      try {
        Typekit.load({ async: false });
      } catch (e) {}
    </script>
  </head>
  <body
    class="html not-logged-in no-sidebars page-node page-node- page-node-1 node-type-page logo_standard animation-dots-processed bootstrap_modal_forms-processed"
    style="margin-bottom: 246px; overflow: hidden"
    [ngClass]="{ front: isHomeScreen }"
  >
    <div id="skip-link">
      <a
        href="https://developer.dexcom.com/#main-content"
        class="element-invisible element-focusable"
        >Skip to main content</a
      >
    </div>
    <app-header></app-header>

    <!-- Breadcrumbs -->
    <div class="container breadcrumb-wrapper" id="breadcrumb-navbar">
      <div class="row">
        <div class="col-md-12"></div>
      </div>
    </div>

    <div class="master-container">
      <router-outlet (activate)="onActivate($event)"></router-outlet>

      <div id="push"></div>
    </div>
    <app-footer></app-footer>

    <script src="../assets/js/bootstrap.js"></script>

    <i title="Raphaël Colour Picker" style="display: none"></i>
  </body>
</html>
