// @ts-nocheck
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

type EnvName = 'local' | 'dev' | 'acceptance' | 'prod';

export const environment = {
  name: (window?.env?.ENV_NAME || 'local') as EnvName,
  apiUrl: window?.env?.DEVELOPER_API_URL || 'http://localhost:8080',
  dexcomApiUrl: window?.env?.PARTNER_API_BASE_URL || 'https://dev.api.us.dexcomdev.com',
  devApiUrl: window?.env?.DEVELOPER_API_URL || 'https://developer-api-dot-g5-dexcom-dev-us-5.uc.r.appspot.com',
  recaptchaSiteKey:
    window?.env?.DEVELOPER_PORTAL_RECAPTCHA_SITEKEY || '6LfcuCYhAAAAAAt-HsEs0LO3BGKzO2cN2AoVli-u',
  adminClaimActionsEnabled: window?.env?.DEVELOPER_PORTAL_ADMIN_CLAIMS_ACTIONS_ENABLED || 'false',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
