<div class="modal-dialog" style="">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
      <h4 class="modal-title" id="loginLabel">Login</h4>
    </div>
    <div class="modal-body">
      <form
        [formGroup]="loginForm"
        (ngSubmit)="login()"
        novalidate="novalidate"
        action="https://developer.dexcom.com/user/login?destination=node/1"
        method="post"
        id="user-login"
        accept-charset="UTF-8"
      >
        <div>
          <div class="form-item form-item-name form-type-textfield form-group">
            <label class="control-label" for="edit-name"
              >Username or Email
              <span class="form-required" title="This field is required.">*</span></label
            >
            <input
              [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"
              formControlName="username"
              autocomplete="off"
              placeholder="name@example.com"
              class="form-control form-text required"
              title=""
              data-toggle="tooltip"
              type="text"
              id="edit-name"
              name="name"
              value=""
              size="60"
              maxlength="60"
              data-original-title="You may login with either your assigned username or your e-mail address."
            />
            <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
              <div *ngIf="f['username'].errors['required']">
                <span class="invalid-text">Username/Email is required</span>
              </div>
            </div>
          </div>
          <div class="form-item form-item-pass form-type-password form-group">
            <label class="control-label" for="edit-pass"
              >Password <span class="form-required" title="This field is required.">*</span></label
            >
            <input
              [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
              formControlName="password"
              autocomplete="off"
              placeholder="Password"
              class="form-control form-text required"
              title=""
              data-toggle="tooltip"
              type="password"
              id="edit-pass"
              name="pass"
              size="60"
              maxlength="128"
              data-original-title="The password field is case sensitive."
            />
            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
              <div *ngIf="f['password'].errors['required']">
                <span class="invalid-text">Password is required</span>
              </div>
            </div>
          </div>
          <input
            type="hidden"
            name="form_build_id"
            value="form-isrtIJK6ZgQHOVYkoS2WlXfoNsnTRv5cznX6y1T8BOI"
          />
          <input type="hidden" name="form_id" value="user_login" />
          <div class="form-actions form-wrapper form-group" id="edit-actions">
            <button
              type="submit"
              id="edit-submit"
              name="op"
              value="Log In"
              class="btn btn-primary form-submit icon-before"
            >
              <span class="icon" aria-hidden="true"></span>Log In
            </button>
          </div>
          <div class="ddp-pass">
            <a  href="/user/forgot-password" >I forgot my Password</a>
          </div>
          <div class="ddp-readme">
            developer.dexcom uses a separate account from the Dexcom store.
          </div>
          <div class="ddp-account">
            Need a developer.dexcom account?
            <a href="/user/register">Create an Account</a>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer"></div>
  </div>
</div>
<div
  id="webform_client_form_49"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="webform_client_form_49Label"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        <h4 id="webform_client_form_49Label" class="modal-title">Unsubscribe</h4>
      </div>
      <div class="modal-body">
        <form
          class="webform-client-form webform-client-form-49"
          novalidate="novalidate"
          action="https://developer.dexcom.com/content/unsubscribe?destination=node/1"
          method="post"
          id="webform-client-form-49"
          accept-charset="UTF-8"
        >
          <div>
            <div class="webform-progressbar">
              <div class="webform-progressbar-outer">
                <div class="webform-progressbar-inner" style="width: 0%">&nbsp;</div>
                <span class="webform-progressbar-page current" style="left: 0%">
                  <span class="webform-progressbar-page-number">1</span>
                  <span class="webform-progressbar-page-label"> Start </span>
                </span>
                <span class="webform-progressbar-page" style="left: 100%">
                  <span class="webform-progressbar-page-number">2</span>
                  <span class="webform-progressbar-page-label"> Complete </span>
                </span>
              </div>
            </div>
            <div
              class="subhead form-item webform-component webform-component-email webform-component--unsubscribe-from-the-update-email-list-by-entering-your-email-below form-group form-item form-item-submitted-unsubscribe-from-the-update-email-list-by-entering-your-email-below form-type-webform-email form-group"
            >
              <label
                class="control-label"
                for="edit-submitted-unsubscribe-from-the-update-email-list-by-entering-your-email-below"
                >Unsubscribe from the update email list by entering your email below.
                <span class="form-required" title="This field is required.">*</span></label
              >
              <input
                required="required"
                class="email form-control form-text form-email required"
                placeholder="example@gmail.com"
                type="email"
                id="edit-submitted-unsubscribe-from-the-update-email-list-by-entering-your-email-below"
                name="submitted[unsubscribe_from_the_update_email_list_by_entering_your_email_below]"
                size="60"
              />
            </div>
            <input type="hidden" name="details[sid]" />
            <input type="hidden" name="details[page_num]" value="1" />
            <input type="hidden" name="details[page_count]" value="1" />
            <input type="hidden" name="details[finished]" value="0" />
            <input
              type="hidden"
              name="form_build_id"
              value="form-amaYeGcIP98V_3-UQOjpfxAFWduFFYw1n_KDa2ukpyc"
            />
            <input type="hidden" name="form_id" value="webform_client_form_49" />
            <input
              type="hidden"
              name="honeypot_time"
              value="1599195216|HY3RgAgbvHgjnb4M_D7n-pmHq91x2AMfex1yNOz3X1Q"
            />
            <div class="url-textfield">
              <div class="form-item form-item-url form-type-textfield form-group">
                <label class="control-label" for="edit-url">Leave this field blank</label>
                <input
                  autocomplete="off"
                  class="form-control form-text"
                  type="text"
                  id="edit-url"
                  name="url"
                  value=""
                  size="20"
                  maxlength="128"
                />
              </div>
            </div>
            <div class="form-actions">
              <button
                class="webform-submit button-primary btn btn-default form-submit"
                type="submit"
                name="op"
                value="Unsubscribe"
              >
                Unsubscribe
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  id="dexcom_search_search_block_modal_form"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dexcom_search_search_block_modal_formLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        <h4 id="dexcom_search_search_block_modal_formLabel" class="modal-title">
          <div></div>
        </h4>
      </div>
      <div class="modal-body">
        <form
          novalidate="novalidate"
          action="https://developer.dexcom.com/search-results?destination=node/1"
          method="post"
          id="dexcom-search-search-block-form"
          accept-charset="UTF-8"
        >
          <div>
            <div class="search-input form-wrapper form-group" id="edit-search-input">
              <span class="search-icon"></span>
              <div class="form-item form-item-search-term form-type-textfield form-group">
                <input
                  placeholder="Search"
                  autofocus=""
                  class="form-control form-text ajax-processed"
                  type="text"
                  id="edit-search-term"
                  name="search_term"
                  value=""
                  size="60"
                  maxlength="128"
                />
              </div>
            </div>
            <div id="results"></div>
            <input
              type="hidden"
              name="form_build_id"
              value="form-_lr4xDx0r4cvHyvelMjftejMCKXb269tczCoNfxI30k"
            />
            <input type="hidden" name="form_id" value="dexcom_search_search_block_form" />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  id="dexcom_user_forms_welcome_modal_form"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dexcom_user_forms_welcome_modal_formLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        <h4 id="dexcom_user_forms_welcome_modal_formLabel" class="modal-title">
          <div></div>
        </h4>
      </div>
      <div class="modal-body">
        <form
          novalidate="novalidate"
          action="https://developer.dexcom.com/welcome-modal?destination=node/1"
          method="post"
          id="dexcom-user-forms-welcome-modal"
          accept-charset="UTF-8"
        >
          <div>
            <input
              type="hidden"
              name="form_build_id"
              value="form-3xKTAxNMmVWC8CpbFdROKmAeH5jlebVQHKGJiWgOHtI"
            />
            <input type="hidden" name="form_id" value="dexcom_user_forms_welcome_modal" />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
