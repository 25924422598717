<div class="page-content">
  <div class="main-container container">
    <div class="row">
      <section class="col-sm-12">
        <div class="region region-content">
          <section id="block-dexcom-front-blocks-cta-1" class="block block-dexcom-front-blocks clearfix">
            <div class="cta-blocks cta-block-wrapper clearfix">
              <video id="block-1-home-video" tabindex="0" autoplay="autoplay" loop="loop" autobuffer="autobuffer"
                preload="preload" muted="muted" oncanplay="this.play()" onloadedmetadata="this.muted = true"
                playsinline="">
                <source type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' src="../assets/wave.mp4" />
              </video>
              <div class="call-to-action-wrapper">
                <div class="call-to-action align-right primary-cta">
                  <h2 class="call-to-action-title">
                    Make Lives &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Better With Us
                  </h2>
                  <p class="call-to-action-body">CGM Data + Your App = Impacting Lives</p>
                  <a href="/docs/dexcom/getting-started/" class="call-to-action-btn btn-tertiary">Start Building</a>
                  <a (click)="scrollDown()" href="javascript:void(0)" id="cta-scroll"><img typeof="foaf:Image"
                    class="img-responsive" src="../assets/image/scroll-down.png" alt=""></a>
                </div>
              </div>
            </div>
          </section>
          <!-- /.block -->
          <section id="block-dexcom-front-blocks-cta-2" class="block block-dexcom-front-blocks clearfix">
            <div class="cta-blocks cta-block-wrapper clearfix">
              <div class="animation-wrapper">
                <div id="cta-2-animation-canvas">
                  <svg height="768" version="1.1" width="1024" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" style="overflow: hidden; position: relative">
                    <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)">
                      Created with Raphaël 2.2.0
                    </desc>
                    <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"></defs>
                    <image x="0" y="0" width="593" height="439" preserveAspectRatio="none"
                      href="../../assets/image/cta_2.png" transform="matrix(1,0,0,1,423,220)"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"></image>
                    <circle cx="915.5" cy="382.5" r="10" fill="#dcebd1" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="842" cy="421" r="13" fill="#bcd7a6" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="766" cy="449" r="15" fill="#a8cb8e" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="687.5" cy="454.5" r="18" fill="#9ac279" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="594" cy="445" r="20" fill="#9ec57f" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="498.5" cy="387.5" r="22" fill="#96c171" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="442.5" cy="315.5" r="24" fill="#88b962" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="337" cy="257" r="38" fill="#59a618" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <path fill="#58585b" stroke="#ffffff"
                      d="M342,254V278.7C342,279.59999999999997,341.7,280.4,341,281C340.3,281.6,339.5,282,338.6,282H334.5C333.6,282,332.7,281.7,332.1,281.1C331.40000000000003,280.5,331.1,279.70000000000005,331.1,278.8V254.10000000000002M345.4,235.9C345.9,236.4,346.4,237.20000000000002,346.9,238.3C348,240.5,348.29999999999995,242.9,347.9,245.3C347.5,247.8,346.4,249.9,344.7,251.60000000000002C342.5,253.90000000000003,339.7,255.00000000000003,336.59999999999997,255.00000000000003C333.4,255.00000000000003,330.7,253.90000000000003,328.49999999999994,251.60000000000002C326.29999999999995,249.3,325.09999999999997,246.60000000000002,325.09999999999997,243.3C325.09999999999997,240.10000000000002,326.2,237.3,328.49999999999994,235C329.19999999999993,234.3,329.99999999999994,233.6,330.99999999999994,233.1C331.99999999999994,232.5,332.8999999999999,232.1,333.79999999999995,232C334.09999999999997,231.9,334.4,232,334.59999999999997,232.2C334.79999999999995,232.39999999999998,334.9,232.7,334.79999999999995,233L332.49999999999994,241.5L336.59999999999997,245.6L342.09999999999997,244C342.09999999999997,243.9,342.29999999999995,243.1,342.7,241.6C343,240.1,343.4,238.79999999999998,343.7,237.6S344.2,235.79999999999998,344.3,235.6C344.5,235.4,344.7,235.29999999999998,344.90000000000003,235.4C345,235.6,345.2,235.7,345.4,235.9L345.4,235.9ZM338,277.5C338,277.1,337.9,276.7,337.6,276.4C337.3,276.09999999999997,337,276,336.5,276C336.1,276,335.7,276.1,335.4,276.4C335.09999999999997,276.7,335,277,335,277.5C335,277.9,335.1,278.3,335.4,278.6C335.7,278.90000000000003,336,279,336.5,279C336.9,279,337.3,278.9,337.6,278.6C337.9,278.3,338,277.9,338,277.5"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 0" fill-opacity="0"
                      stroke-width="2.15"></path>
                    <circle cx="216" cy="219" r="38" fill="#59a618" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <path fill="#58585b" stroke="#ffffff"
                      d="M232,220C232,232.2,225.1,242,216.5,242S201,232.2,201,220S207.9,198,216.5,198S232,207.8,232,220L232,220ZM201,216H232M216,198V216M216,199C215.7,193.2,219.3,190,224.2,190C229.29999999999998,190,236,191.1,236,185"
                      fill-opacity="0" stroke-width="2.15"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 0"></path>
                    <circle cx="98" cy="257" r="38" fill="#59a618" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <path fill="#58585b" stroke="#ffffff"
                      d="M108.2,245C104.8,243.8,101.5,245.2,101.2,249C98.9,248.8,94.2,249,94.2,249S93.8,244.5,89.10000000000001,244C85.9,243.7,83.4,244.7,80.10000000000001,247.7C80.10000000000001,247.39999999999998,80.10000000000001,246.79999999999998,80.10000000000001,246.2C80.10000000000001,235.29999999999998,95.2,234.29999999999998,100.4,236.2C106.2,238.29999999999998,108.2,237.6,108.2,237.6C108.5,236.1,109,235,110.5,235H112.5C113.9,235,115.1,236.2,115.1,237.6V244.5C115.1,245.9,113.89999999999999,247.1,112.5,247.1H110.5C109,247,108.2,245,108.2,245L108.2,245ZM101.1,249L102,280C102,281.6,100.7,283,99.1,283H95.8C94.2,283,92.89999999999999,281.7,92.89999999999999,280L94.1,249M94,257H101"
                      fill-opacity="0" stroke-width="2.15"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 0"></path>
                  </svg>
                </div>
              </div>
              <div class="call-to-action-wrapper">
                <div class="call-to-action align-left">
                  <h2 class="call-to-action-title">
                    Learn how to use our API to build your diabetes app
                  </h2>
                  <p class="call-to-action-body">
                    The Dexcom API enables the development of innovative apps that amplify the value
                    and utility of CGM data
                  </p>
                  <a href="/docs" class="call-to-action-btn btn-primary">Learn More</a>
                </div>
              </div>
            </div>
          </section>
          <!-- /.block -->
          <section id="block-dexcom-front-blocks-cta-3" class="block block-dexcom-front-blocks clearfix">
            <div class="cta-blocks cta-block-wrapper clearfix">
              <div class="animation-wrapper">
                <div id="cta-3-animation-canvas">
                  <svg height="768" version="1.1" width="1024" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" style="overflow: hidden; position: relative">
                    <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)">
                      Created with Raphaël 2.2.0
                    </desc>
                    <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"></defs>
                    <image x="0" y="0" width="322" height="586" preserveAspectRatio="none"
                      href="../../assets/image/cta_3.png" transform="matrix(1,0,0,1,60,128)"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"></image>
                    <circle cx="124.5" cy="360.5" r="13" fill="#dcebd1" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="179.5" cy="401.5" r="15" fill="#bcd7a6" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="236.5" cy="441.5" r="17" fill="#a8cb8e" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="313.5" cy="459.5" r="19" fill="#9ac279" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="402.5" cy="440.5" r="21" fill="#9ec57f" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="481" cy="390" r="23" fill="#96c171" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="551.5" cy="310.5" r="25" fill="#88b962" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <circle cx="656" cy="257" r="38" fill="#59a618" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <path fill="#58585b" stroke="#ffffff"
                      d="M656,243C656,240.3,654,238,654,238M665.3,244C660.9,244,659.1999999999999,246.5,655.5999999999999,246.5C651.8999999999999,246.5,650.3,244,645.8,244C637.6999999999999,244,636.0999999999999,250.9,636.0999999999999,256.3C635.9999999999999,266.1,642.8999999999999,276,648.1999999999999,276C651.4999999999999,276,651.9999999999999,273.5,655.4999999999999,273.5C659.0999999999999,273.5,659.5999999999999,276,662.7999999999998,276C667.4999999999999,276,674.9999999999999,266.1,674.9999999999999,256.3C675,250.9,672.6,244,665.3,244L665.3,244Z"
                      fill-opacity="0" stroke-width="2.15"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 0"></path>
                    <circle cx="785" cy="244" r="38" fill="#59a618" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <path fill="#58585b" stroke="#ffffff"
                      d="M796,248H776M776,240H796M770.9,228C768.6,228,766.8,229.8,766.8,232V236.1C766.5999999999999,236,766.3,236,766.0999999999999,236C764.9999999999999,236,764.0999999999999,236.9,764.0999999999999,238V240C762.9999999999999,240,762.0999999999999,240.9,762.0999999999999,242V246C762.0999999999999,247.1,762.9999999999999,248,764.0999999999999,248V250C764.0999999999999,251.1,764.9999999999999,252,766.0999999999999,252C766.3999999999999,252,766.5999999999999,251.9,766.8,251.9V256C766.8,258.2,768.5999999999999,260,770.9,260C773.1999999999999,260,775,258.2,775,256V232C775,229.8,773.2,228,770.9,228L770.9,228ZM807,240V238C807,236.9,806.1,236,805,236C804.7,236,804.5,236.1,804.3,236.1V232C804.3,229.8,802.5,228,800.1999999999999,228S796.0999999999999,229.8,796.0999999999999,232V256C796.0999999999999,258.2,797.8999999999999,260,800.1999999999999,260S804.3,258.2,804.3,256V251.9C804.5,252,804.8,252,805,252C806.1,252,807,251.1,807,250V248C808.1,248,809,247.1,809,246V242C809,240.9,808.1,240,807,240L807,240Z"
                      fill-opacity="0" stroke-width="2.15"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 0"></path>
                    <circle cx="916" cy="296" r="38" fill="#59a618" stroke="#000" fill-opacity="1" stroke-width="0"
                      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 1"></circle>
                    <path fill="#58585b" stroke="#ffffff"
                      d="M935,290.5H921.5V277H910.5V290.5H897V301.5H910.5V315H921.5V301.5H935V290.5Z" fill-opacity="0"
                      stroke-width="2.15" style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0); fill-opacity: 0"></path>
                  </svg>
                </div>
              </div>
              <div class="call-to-action-wrapper">
                <div class="call-to-action align-right">
                  <h2 class="call-to-action-title">
                    Learn more about how CGM data can be used in your app
                  </h2>
                  <p class="call-to-action-body">
                    Set your app apart by integrating Dexcom CGM data, including estimated glucose
                    values, events, statistics, and more
                  </p>
                  <a href="/docs" class="call-to-action-btn btn-primary">Learn More</a>
                </div>
              </div>
            </div>
          </section>
          <!-- /.block -->
          <section id="block-system-main" class="block block-system pull-right row clearfix"></section>
          <!-- /.block -->
        </div>
      </section>
    </div>
  </div>
</div>
