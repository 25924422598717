import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  showBackdrop = false;
  fullImageUrl: string = '';
  galleryImageUrl: string = 'https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/';
  galleryData = [
    {
      name: '1bios',
      displayName: '1bios',
      url: 'https://1bios.co/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/1bios-health/id918178962?mt=8',
        google: 'https://play.google.com/store/apps/details?id=co.bios.app&amp;hl=en',
      },
      icon: this.galleryImageUrl+'screenshots/icon_spaced2.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/1bios-screenshot-1_dexcom.png',
        this.galleryImageUrl+'screenshots/1bios-screenshot-2_dexcom.png',
        this.galleryImageUrl+'screenshots/1bios-screenshot-3_dexcom.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/1bios-screenshot-1_dexcom.png',
        this.galleryImageUrl+'fullimages/1bios-screenshot-2_dexcom.png',
        this.galleryImageUrl+'fullimages/1bios-screenshot-3_dexcom.png',
      ],
      desc: '1bios health management software supports payers and providers in the shift towards value-based care. We lower healthcare costs with tools that make it more efficient to support the better health of large populations—including people with or at risk of developing diabetes. How? By making it easy for health care organizations to monitor, analyze and stay connected to their members, patients and communities 365 days/year. And, by making it simple, relevant, and rewarding for you to participate.\n\n',
    },
    {
      name: 'achievement',
      displayName: 'Achievement',
      url: 'https://www.myachievement.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/achievemint/id793039965',
        google: 'https://play.google.com/store/apps/details?id=com.achievemint.android',
      },
      icon: this.galleryImageUrl+'screenshots/120x1203x.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/android_1.png',
        this.galleryImageUrl+'screenshots/android_3.png',
        this.galleryImageUrl+'screenshots/android_5.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/android_1.png',
        this.galleryImageUrl+'fullimages/android_3.png',
        this.galleryImageUrl+'fullimages/android_5.png',
      ],
      desc: 'Achievement allows individuals to earn rewards for health actions, receive personalized offers about health programs, and participate in research.\n\n',
    },
    {
      name: 'blue_star',
      displayName: 'BlueStar',
      url: 'http://www.bluestardiabetes.com/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/bluestar-diabetes/id700329056?mt=8',
        google:
          'https://play.google.com/store/apps/details?id=com.welldoc.platform.android&hl=en_US',
      },
      icon: this.galleryImageUrl+'screenshots/bluestar_logo_120px.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/logbook_w_entries_cgm_604w.png',
        this.galleryImageUrl+'screenshots/apps_devices_disconnect_604w.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/logbook_w_entries_cgm_604w.png',
        this.galleryImageUrl+'fullimages/apps_devices_disconnect_604w.png',
      ],
      desc: 'Welldoc’s marquee product, BlueStar® is an FDA-cleared, award-winning, digital therapeutic for individuals with diabetes. BlueStar serves as an in-app digital assistant to help people better self-manage the complexities of their diabetes. As a web and mobile app, BlueStar delivers individualized, real-time feedback and longitudinal insights that are actionable and highly-tailored to the individual user. BlueStar connects and shares your progress directly with your care team.\n\n',
    },
    {
      name: 'center_health',
      displayName: 'Center Health',
      url: 'https://www.center.health/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/app/center-the-diabetes-app/id1231963619?utm_medium=gallery&utm_source=dexcom',
        google:
          'https://play.google.com/store/apps/details?id=com.distillr.app&utm_source=dexcom&utm_medium=gallery',
      },
      icon: this.galleryImageUrl+'screenshots/icon-120x120.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/1.png',
        this.galleryImageUrl+'screenshots/2.png',
        this.galleryImageUrl+'screenshots/3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/1.png',
        this.galleryImageUrl+'fullimages/2.png',
        this.galleryImageUrl+'fullimages/3.png',
      ],
      desc: 'Center Health is turning any phone into an AI-based monitoring solution for the 1 in 15 humans who suffer from diabetes. Through the combination of simple logging, collaboration, and the world’s first virtual assistant for people with diabetes, Center empowers patients to see what lifestyle elements are affecting their glucose and prompts behavior change to better manage the condition.\n\n',
    },
    {
      name: 'dreamed',
      displayName: 'DreaMed Advisor Pro',
      url: 'https://dreamed-diabetes.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/dreamed_circle.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/2_0.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/2_0.png',
      ],
      desc: 'DreaMed Diabetes is a medical device company providing personalized diabetes treatment optimization solutions. DreaMed Advisor Pro applies event-driven adaptive learning to refine its understanding for each individual. The solution analyzes information from CGM, self-monitoring blood glucose (SMBG) and insulin delivery devices, and sends recommendations to healthcare providers on how to optimize patient insulin pump settings for basal rate, carbohydrate ratio (CR) and correction factor (CF).\n\n',
    },
    {
      name: 'fitabase',
      displayName: 'Fitabase',
      url: 'http://www.fitabase.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/circlelogo_120_120.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/dexcom_resize_1.png',
        this.galleryImageUrl+'screenshots/dexcom_resize_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/dexcom_resize_1.png',
        this.galleryImageUrl+'fullimages/dexcom_resize_2.png',
      ],
      desc: 'Fitabase is a data management platform that allows researchers to capture and analyze high-resolution data from wearable devices, including Dexcom’s CGM systems. Data streams directly to Fitabase and can be exported from individual participants or in custom batches for your whole study. Fitabase has been helping researchers access and make sense of data from wearable devices since 2012, and since has supported more than 500 studies.\n\n',
    },
    {
      name: 'glooko',
      displayName: 'Glooko',
      url: 'http://glooko.com/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/glooko-track-diabetes-data/id471942748',
        google: 'https://play.google.com/store/apps/details?id=com.glooko.logbook',
      },
      icon: this.galleryImageUrl+'screenshots/glooko-logo-large.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/ios_home_.png',
        this.galleryImageUrl+'screenshots/ios_history_png.png',
        this.galleryImageUrl+'screenshots/ios_overlay.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/ios_home_.png',
        this.galleryImageUrl+'fullimages/ios_history_png.png',
        this.galleryImageUrl+'fullimages/ios_overlay.png',
      ],
      desc: 'Glooko insights help people with diabetes (PWD) and care teams improve diabetes management. The Glooko Mobile App enables self-management and automatically uploads data from blood glucose meters, insulin pumps, CGMs and fitness and activity trackers—providing timely and verified data and enabling PWDs and care teams to correlate it with diet, exercise, medication and other data. Trusted by the world’s leaders in diabetes care, Glooko is used by 7,000 clinics and 1.5 million PWD globally.\n\n',
    },
    {
      name: 'glucose_buddy',
      displayName: 'Glucose Buddy',
      url: 'https://azumio.com/solutions#diabetes',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/glucose-buddy-diabetes-tracker/id294754639',
        google: 'https://play.google.com/store/apps/details?id=com.skyhealth.glucosebuddyfree',
      },
      icon: this.galleryImageUrl+'screenshots/dexcom-gb-app-icon_1.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/dexcom-gb-screenshot1_2.png',
        this.galleryImageUrl+'screenshots/dexcom-gb-screenshot2_1.png',
        this.galleryImageUrl+'screenshots/dexcom-gb-screenshot3_1.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/dexcom-gb-screenshot1_2.png',
        this.galleryImageUrl+'fullimages/dexcom-gb-screenshot2_1.png',
        this.galleryImageUrl+'fullimages/dexcom-gb-screenshot3_1.png',
      ],
      desc: 'For over 10 years, Glucose Buddy has helped people manage their glucose, insulin, medication and diet. Meal IQ combines our food recognition system with your glucose measurements to help you understand how your meals impact your glucose. With Meal IQ you will receive food grades so you can make better dietary choices as well as detailed reports to help you regulate your glucose and A1c.\n\n',
    },
    {
      name: 'glucose_zone',
      displayName: 'GlucoseZone',
      url: 'https://glucosezone.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/glucosezone/id1140645765',
        google: 'https://play.google.com/store/apps/details?id=com.fitscript.glucosezone',
      },
      icon: this.galleryImageUrl+'screenshots/gzlogo-120px.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/image_from_ios-2.jpg',
        this.galleryImageUrl+'screenshots/iphone_x_copy_23.png',
        this.galleryImageUrl+'screenshots/iphone_x_copy_21.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/image_from_ios-2.png',
        this.galleryImageUrl+'fullimages/iphone_x_copy_23.png',
        this.galleryImageUrl+'fullimages/iphone_x_copy_21.png',
      ],
      desc: 'GlucoseZone is a digital therapeutic that helps you meet the exercise requirement of your diabetes management. GlucoseZone provides you with individualized exercise guidance based on your glucose levels and other health metrics. At GlucoseZone, we understand that exercise is different for people living with diabetes, starting with the need to check glucose—let GlucoseZone help you say yes to exercise.\n\n',
    },
    {
      name: 'inpen',
      displayName: 'InPen',
      url: 'https://www.companionmedical.com/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/companion-inpen-app/id1236236656',
        google: 'https://play.google.com/store/apps/details?id=com.companionmedical.inpen',
      },
      icon: this.galleryImageUrl+'screenshots/inpen_circle_120x120.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/inpen_slide_2.png',
        this.galleryImageUrl+'screenshots/inpen_slide_1.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/inpen_slide_2.png',
        this.galleryImageUrl+'fullimages/inpen_slide_1.png',
      ],
      desc: 'InPen, by Companion Medical, combines a smart insulin pen with an easy-to-use smartphone app. Using information transmitted from the pen, the InPen App automatically records insulin doses, tracks active insulin, calculates mealtime and correction doses, shares therapy data with your doctor or caregiver, and much more.\n\n',
    },
    {
      name: 'meteda',
      displayName: 'Meteda',
      url: 'http://www.meteda.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/logo-meteda-120x120px.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/meteda_app_gallery_1.png',
        this.galleryImageUrl+'screenshots/meteda_app_gallery_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/meteda_app_gallery_1.png',
        this.galleryImageUrl+'fullimages/meteda_app_gallery_2.png',
      ],
      desc: "METEDA's MètaClinic is a professional electronic health record to manage clinical data of patients with chronic conditions like diabetes. The system facilitates storing and processing of clinical and self-monitoring data of patients. Efficiently cataloged and with advanced Clinical Decision Support Tools, data can be quickly consulted both by hospitals and outpatient facilities.\n\n",
    },
    {
      name: 'my_diabby',
      displayName: 'myDiabby',
      url: 'https://www.mydiabby.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/fr/app/mydiabby/id1250774682',
        google: 'https://play.google.com/store/apps/details?id=fr.be4life.mydiabbydt&hl=fr',
      },
      icon: this.galleryImageUrl+'screenshots/mydiabby_logo_circle.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/picture1.png',
        this.galleryImageUrl+'screenshots/picture2.png',
        this.galleryImageUrl+'screenshots/picture3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/picture1.png',
        this.galleryImageUrl+'fullimages/picture2.png',
        this.galleryImageUrl+'fullimages/picture3.png',
      ],
      desc: 'myDiabby is telemedicine software for clinics to manage their patients with type 1 and type 2 diabetes, bringing all their data into one place and enabling coordination across the entire care team. It consists of a mobile app for patients to connect their devices and log data as well as an online software portal for HCPs to streamline their in-person and telemedicine workflows.\n\n',
    },
    {
      name: 'one_drop',
      displayName: 'One Drop',
      url: 'http://onedrop.today/',
      appStoreLink: {
        apple:
          'https://itunes.apple.com/us/app/one-drop-for-diabetes-management/id972238816?mt=8&utm_source=Dexcom&utm_medium=app%20gallery',
        google:
          'https://play.google.com/store/apps/details?id=today.onedrop.android&hl=en&utm_source=Dexcom&utm_medium=app%20gallery',
      },
      icon: this.galleryImageUrl+'screenshots/onedrop-iconcircle.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/onedrop-graph_daily-604w.png',
        this.galleryImageUrl+'screenshots/onedrop-communityv2.0-604w.png',
        this.galleryImageUrl+'screenshots/onedrop-graph_gir-604w.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/onedrop-graph_daily-604w.png',
        this.galleryImageUrl+'fullimages/onedrop-communityv2.0-604w.png',
        this.galleryImageUrl+'fullimages/onedrop-graph_gir-604w.png',
      ],
      desc: 'One Drop | Mobile is an award-winning, cloud-based diabetes management solution available on iOS and Android. Track glucose, food, medication, and activity data; get real-time insights and detailed glucose analytics; share reports with your healthcare team; and interact with a worldwide community of people with diabetes. One Drop | Mobile also includes a fully featured Apple WatchOS app for tracking and analyzing diabetes data on-the-go.\n\n',
    },
    {
      name: 'rimidi',
      displayName: 'Rimidi',
      url: 'https://www.rimidi.com/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/diabetes-me/id1072889742',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/screenshot-mobile-my-info.png',
        this.galleryImageUrl+'screenshots/screenshot-mobile-dashboard.png',
        this.galleryImageUrl+'screenshots/screenshot-log.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/screenshot-mobile-my-info.png',
        this.galleryImageUrl+'fullimages/screenshot-mobile-dashboard.png',
        this.galleryImageUrl+'fullimages/screenshot-log.png',
      ],
      desc: "Rimidi's clinical management platform marries patient generated data, including BGM and CGM, with clinical data from the EMR into a streamlined user experience optimized for workflow efficiency. Clinicians monitor key quality measures, triage patient needs, and identify specific gaps in care. Our unique predictive analytics allow clinicians to visualize the anticipated impact of treatment decisions on patient outcomes. Patients get timely feedback and better insight into their care.\n\n",
    },
    {
      name: 'seqster',
      displayName: 'Seqster',
      url: 'https://seqster.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/seqster_120x120.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/seqster_image1_604px.png',
        this.galleryImageUrl+'screenshots/seqster_image2_604px.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/seqster_image1_604px.png',
        this.galleryImageUrl+'fullimages/seqster_image2_604px.png',
      ],
      desc: 'Seqster is a data-driven healthcare SaaS platform that empowers your members to collect, own, and share their data. By gathering all health data in one place, Seqster allows their users to visualize their health in a meaningful way. Seqster can connect over 9,300 healthcare providers, dozens of major health/fitness devices, and the top genetic consumer labs around.\n\n',
    },
    {
      name: 'social_diabetes',
      displayName: 'SocialDiabetes',
      url: 'https://www.socialdiabetes.com/en/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/1bios-health/id918178962?mt=8',
        google: 'https://play.google.com/store/apps/details?id=co.bios.app&amp;hl=en',
      },
      icon: this.galleryImageUrl+'screenshots/sd_logo_dexcom.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/screenchot02-sd-dexcom.jpg',
        this.galleryImageUrl+'screenshots/screenshot01-sd-dexcom.jpg',
        this.galleryImageUrl+'screenshots/screen03_newlog-sd.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/screenchot02-sd-dexcom.jpg',
        this.galleryImageUrl+'fullimages/screenshot01-sd-dexcom.jpg',
        this.galleryImageUrl+'fullimages/screen03_newlog-sd.png',
      ],
      desc: 'SocialDiabetes is an award-winning digital management solution that helps people with diabetes improve their daily life and clinical outcomes. Register your glucose levels, insulin dosage, carb intake, medications and physical activity; share your digital logbook with your physician and analyze your data with different charts; upload data automatically from a wide range of glucose meters and connect with your doctor or nurse to create the best treatment for you.\n\n',
    },
    {
      name: 'steady_health',
      displayName: 'Steady Health',
      url: 'https://steady.health/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/app/id1458929653',
        google: 'https://play.google.com/store/apps/details?id=com.steadyhealth',
      },
      icon: this.galleryImageUrl+'screenshots/rectangular_single_letter.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/screen_2.png',
        this.galleryImageUrl+'screenshots/screen_3.png',
        this.galleryImageUrl+'screenshots/screen_1.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/screen_2.png',
        this.galleryImageUrl+'fullimages/screen_3.png',
        this.galleryImageUrl+'fullimages/screen_1.png',
      ],
      desc:
        'Steady Health provides technology-enabled modern care for diabetes. We specialize in CGM, use data and technology to understand our members, help them set goals, and coach them to get there. Book appointments, refill prescriptions and get coaching from our team of doctors and nurses through our simple app.\n' +
        '\n' +
        'We integrate with Dexcom CGM data and also enable our users to share insulin, exercise and much more.\n' +
        '\n' +
        'Get modern care and better health with our membership:\n' +
        '\n' +
        'Individualized treatment plans based on your data\n' +
        'Direct access to your doctor\n' +
        'Help with nutrition, exercise & technology\n' +
        'Improve your Time in Range, A1C, energy & mood\n' +
        'Now accepting members in our first San Francisco location\n\n',
    },
    {
      name: 'tidepool',
      displayName: 'Tidepool',
      url: 'https://tidepool.org/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/tidepool-mobile/id1026395200',
        google: 'https://play.google.com/store/apps/details?id=io.tidepool.urchin',
      },
      icon: this.galleryImageUrl+'screenshots/tidepool-logo-120.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/iphone.png',
        this.galleryImageUrl+'screenshots/android.png',
        this.galleryImageUrl+'screenshots/web.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/iphone.png',
        this.galleryImageUrl+'fullimages/android.png',
        this.galleryImageUrl+'fullimages/web.png',
      ],
      desc: 'Tidepool is free, nonprofit software that liberates data from pumps, BGMs and CGMs and lets you see it all together in one place on the web or your phone.\n\n',
    },
    {
      name: 'under_my_fork',
      displayName: 'Undermyfork',
      url: 'https://www.undermyfork.com/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/app/apple-store/id1440135674?pt=118556655&ct=dexcom-gallery&mt=8',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/umf_logo_512_0.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/insights_green_0.png',
        this.galleryImageUrl+'screenshots/camera_0.png',
        this.galleryImageUrl+'screenshots/main_screen_0.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/insights_green_0.png',
        this.galleryImageUrl+'fullimages/camera_0.png',
        this.galleryImageUrl+'fullimages/main_screen_0.png',
      ],
      desc: 'Undermyfork lets you track what you eat in the simplest way possible: by overlaying your Dexcom CGM data over photos of your meals.​ Tag your meal photos with keywords to compare your glycemic response to the same meals and situations. The app then classifies meals by postprandial time-in-range and lets you analyze which meal choices may be best for you. You can filter the insights by tags you have added on meal photos.\n\n',
    },
    {
      name: 'validic',
      displayName: 'Validic',
      url: 'https://validic.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/validic_circular-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/hero_graphic_2018.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/hero_graphic_2018.png',
      ],
      desc: "Validic guides healthcare organizations through the technical complexities associated with accessing and operationalizing patient-generated health data. Validic's scalable, secure solutions help you improve operational efficiency and patient outcomes by delivering personal health data from hundreds of home health devices seamlessly into your existing clinical workflows.\n\n",
    },
  ];

  currentPartner = this.galleryData[0];

  constructor() {}

  ngOnInit(): void {}

  openFullImageModal(imageURL: string) {
    this.fullImageUrl = imageURL;
    this.showBackdrop = true;
  }

  closeFullImageModal() {
    this.fullImageUrl = '';
    this.toggleAppModal();
  }

  toggleAppModal(event?: MouseEvent) {
    const dialog = document.getElementById('jquery_ajax_load') as HTMLInputElement;
    const anchorElement = event?.target as HTMLAnchorElement;
    if (anchorElement && !this.showBackdrop) {
      this.currentPartner = this.galleryData.filter((p) => {
        if (anchorElement) return anchorElement.id.indexOf(p.name) !== -1;
      })[0];
      this.currentPartner = this.currentPartner || this.galleryData[0];
    }
    if (dialog.style.display === 'block') {
      if (
        !$(anchorElement).closest('#jquery_ajax_load .modal-content').length ||
        anchorElement?.id === 'close_modal_button'
      ) {
        dialog.style.display = '';
        dialog.classList.remove('in');
        this.showBackdrop = false;
      }
      if (this.fullImageUrl) {
        dialog.style.display = '';
        dialog.classList.remove('in');
        this.showBackdrop = true;
      }
    } else {
      dialog.style.display = 'block';
      dialog.classList.add('in');
      this.showBackdrop = true;
    }
  }
}
