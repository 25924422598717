import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  submitted = false;
  errMsg = '';
  returnUrl = '';
  validRouteUrls = [
    '/support/support-requests',
    '/support/full-access-upgrade-request-info',
    '/support/terms-and-conditions',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  login(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.authService.login(this.username.value, this.password.value).subscribe({
        next: () => {
          this.closeModal();
          const current = this.router.url;
          this.router.navigate(['/']).then(() => {
            if (this.validRouteUrls.includes(current)) this.router.navigate([current]);
          });
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.toastService.error('Username/email address or password were incorrect.');
          } else {
            this.toastService.error(
              'An unexpected error has occurred while trying to log in, please try again later.'
            );
          }
        },
      });
    }
  }

  closeModal(): void {
    const dialog = document.getElementById('login') as HTMLInputElement;
    if (dialog.style.display === 'block') {
      dialog.style.display = '';
      dialog.classList.remove('in');
    }
  }

  get username(): AbstractControl {
    return this.loginForm.controls['username'];
  }
  get password(): AbstractControl {
    return this.loginForm.controls['password'];
  }
}
