<style>
  @import url('../../assets/css/dexcom_app_gallery.css');
</style>

<div class="page-content">
  <div class="main-container container">
    <div class="row">
      <section class="col-sm-12">
        <!-- Title Prefix -->
        <!-- Title -->
        <h1 class="page-title">App Gallery</h1>
        <!-- SubTitle -->
        <!-- Title Suffix -->

        <div class="region region-content">
          <section id="block-system-main" class="block block-system pull-right clearfix">
            <div
              class="view view-app-gallery view-id-app_gallery view-display-id-gallery view-dom-id-d65f3014f4be000c37c8b1b99b355859 app-gallery-four-col">
              <div class="view-header">
                <div class="header-title">
                  <h1>Dexcom App Gallery</h1>
                </div>
              </div>

              <div class="view-content">
                <div class="views-row views-row-1 views-row-odd views-row-first">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content enable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-116 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="116"><img id="icon_1bios" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/icon_spaced2.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">1bios</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          1bios is a health management, monitoring, and engagement tool for your
                          insurer or care provider to support and reward your better health.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a (click)="toggleAppModal($event)" id="link_1bios"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-116 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="116">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-2 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-112 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="112"><img id="icon_achievement" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/120x1203x.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Achievement</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Achievement allows individuals to earn rewards for health actions, receive
                          personalized offers about health programs, and participate in research.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_achievement" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-112 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="112">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-3 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content enable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-143 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="143"><img id="icon_blue_star" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/bluestar_logo_120px.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">BlueStar</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>BlueStar®, an in-app coach for people living with diabetes.</p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_blue_star" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-143 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="143">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-4 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content enable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-114 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="114"><img id="icon_center_health" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/icon-120x120.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Center Health</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          An easier way to live with diabetes combining simple logging,
                          collaboration, and your very own personal assistant!
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_center_health" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-114 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="114">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-5 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content enable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-152 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="152"><img id="icon_dreamed" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/dreamed_circle.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">DreaMed Advisor Pro</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          DreaMed Advisor Pro is a cloud-based digital solution generating insulin
                          delivery recommendations.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_dreamed" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-152 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="152">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-6 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-147 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="147"><img id="icon_fitabase" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/circlelogo_120_120.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Fitabase</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Fitabase is a data management platform that allows researchers to capture
                          and analyze high-resolution data from wearable devices.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_fitabase" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-147 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="147">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-7 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-115 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="115"><img id="icon_glooko" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/glooko-logo-large.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Glooko</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Mobile &amp; Web app that combines glucose, pump, exercise, food &amp;
                          medications and provides insights for daily decision making.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_glooko" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-115 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="115">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-8 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-144 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="144"><img id="icon_glucose_buddy" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/dexcom-gb-app-icon_1.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Glucose Buddy</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Glucose Buddy with Meal IQ allows you to track your diet with food photos
                          and provides feedback on how the food you eat impacts your glucose.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_glucose_buddy" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-144 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="144">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-9 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-148 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="148"><img id="icon_glucose_zone" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/gzlogo-120px.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">GlucoseZone</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          GlucoseZone is a digital therapeutic designed to help you reach your
                          diabetes and exercise goals.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_glucose_zone" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-148 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="148">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-10 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-149 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="149"><img id="icon_inpen" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/inpen_circle_120x120.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">InPen</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          InPen, by Companion Medical, is the first and only FDA-cleared smart pen
                          for insulin delivery.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_inpen" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-149 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="149">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-11 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content enable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154"><img id="icon_meteda" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/logo-meteda-120x120px.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Meteda</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          METEDA's MètaClinic is a professional electronic health record to manage
                          clinical data of patients with chronic conditions like diabetes
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_meteda" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-154 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="154">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-12 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-155 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="155"><img id="icon_my_diabby" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/mydiabby_logo_circle.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">myDiabby</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          myDiabby is telemedicine software for clinics to manage their patients
                          with type 1 and type 2 diabetes, bringing all their data into one place
                          and enabling coordination across the entire care team.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_my_diabby" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-155 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="155">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-13 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-108 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="108"><img id="icon_one_drop" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/onedrop-iconcircle.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">One Drop</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>Everything you need to manage diabetes, in one place.</p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_one_drop" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-108 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="108">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-14 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-109 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="109"><img id="icon_rimidi" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/logo.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Rimidi</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Rimidi's diabetes management platform turns population health into
                          personalized management that is effective and efficient.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_rimidi" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-109 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="109">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-15 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-150 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="150"><img id="icon_seqster" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/seqster_120x120.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Seqster</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Integrate all your health, DNA, fitness, and medical device data in one
                          place, visualized to better manage your health.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_seqster" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-150 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="150">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-16 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content enable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-146 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="146"><img id="icon_social_diabetes" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/sd_logo_dexcom.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">SocialDiabetes</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Mobile &amp; web app that combines glucose, carb counter, exercise, &amp;
                          medications in one place.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_socia_diabetes" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-146 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="146">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-17 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content enable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-151 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="151"><img id="icon_steady_health" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/rectangular_single_letter.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Steady Health</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>Steady Health provides technology-enabled modern care for diabetes.</p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_steady_health" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-151 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="151">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-18 views-row-even">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-110 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="110"><img id="icon_tidepool" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/tidepool-logo-120.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Tidepool</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>Free software combining pump, BGM, CGM in one place.</p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_tidepool" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-110 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="110">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-19 views-row-odd">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content disable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-153 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="153"><img id="icon_under_my_fork" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/umf_logo_512_0.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Undermyfork</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Undermyfork overlays your Dexcom CGM data over photos of your meals, then
                          classifies your meals by postprandial time-in-range.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_under_my_fork" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-153 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="153">Learn More</a></span>
                    </div>
                  </div>
                </div>
                <div class="views-row views-row-20 views-row-even views-row-last">
                  <div class="app-gallery-wrapper app-gallery-wrapper-image views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-field-app-logo">
                      <div class="field-content enable-logo-border">
                        <a (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-145 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="145"><img id="icon_validic" typeof="foaf:Image" class="img-responsive"
                            src="https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/screenshots/validic_circular-01.png" alt="" /></a>
                      </div>
                    </div>
                  </div>

                  <div class="app-gallery-wrapper app-gallery-wrapper-info views-fieldset"
                    data-module="views_fieldsets">
                    <div class="views-field views-field-title">
                      <h2 class="field-content">Validic</h2>
                    </div>
                    <div class="views-field views-field-body">
                      <div class="field-content">
                        <p>
                          Validic's advanced data connectivity platform removes the burdens
                          associated with data access, integration, standardization, and storage.
                        </p>
                      </div>
                    </div>
                    <div class="views-field views-field-nid">
                      <span class="field-content"><a id="link_validic" (click)="toggleAppModal($event)"
                          class="twitter_bootstrap_modal_tb bs_modal learn-more-145 learn-more jquery-once-3-processed gal-app-lm-processed"
                          data-nid="145">Learn More</a></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="view-footer">
                <p align="center">
                  <em>These apps are made available by third parties independent of Dexcom and
                    inclusion in this Gallery is not approval or endorsement by Dexcom of these
                    third-party apps.</em>
                </p>
              </div>
            </div>
          </section>
          <!-- /.block -->
        </div>
      </section>
    </div>
  </div>
</div>

<div id="jquery_ajax_load" class="modal fade in prepare-inner-modal-processed" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="false" (click)="toggleAppModal($event)">
  <div class="ctools-modal-dialog modal-dialog node-type-app">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" id="close_modal_button" class="close" data-dismiss="modal" aria-hidden="true">
          ×
        </button>
      </div>
      <div class="modal-body">
        <div id="node-107" class="node node-app has-screenshots clearfix" about="/content/ensa"
          typeof="sioc:Item foaf:Document">
          <div class="field field-name-field-app-logo field-type-image field-label-hidden enable-logo-border">
            <div class="field-items">
              <div class="field-item even">
                <img typeof="foaf:Image" class="img-responsive" src="{{ currentPartner.icon }}" alt="" />
              </div>
            </div>
          </div>
          <h2 class="title">{{ currentPartner.displayName }}</h2>

          <div class="content">
            <div class="field field-name-body field-type-text-with-summary field-label-hidden">
              <div class="field-items">
                <div class="field-item even" property="content:encoded" style="white-space: pre-wrap">
                  {{ currentPartner.desc }}
                </div>
              </div>
            </div>
            <div class="field field-name-field-app-website-link field-type-link-field field-label-hidden">
              <div class="field-items">
                <div class="field-item even">
                  <a href="{{ currentPartner.url }}" target="_blank">{{ currentPartner.url }}</a>
                </div>
              </div>
            </div>
            <div class="field field-name-field-app-screen-shot field-type-image field-label-hidden">
              <div class="field-items">
                <div class="field-item even">
                  <a (click)="openFullImageModal(currentPartner.fullImages[0])"
                    class="twitter_bootstrap_modal_TB bs_modal tb-trigger-processed tb-modal-trigger-processed"
                    data-target="#jquery_ajax_load_inner" data-toggle="modal">
                    <img typeof="foaf:Image" class="img-responsive" src="{{ currentPartner.screenShot[0] }}"
                      alt="" /></a>
                </div>
                <div class="field-item odd">
                  <a (click)="openFullImageModal(currentPartner.fullImages[1])"
                    class="twitter_bootstrap_modal_TB bs_modal tb-trigger-processed tb-modal-trigger-processed"
                    data-target="#jquery_ajax_load_inner" data-toggle="modal">
                    <img typeof="foaf:Image" class="img-responsive" src="{{ currentPartner.screenShot[1] }}"
                      alt="" /></a>
                </div>
                <div class="field-item even">
                  <a (click)="openFullImageModal(currentPartner.fullImages[2])"
                    class="twitter_bootstrap_modal_TB bs_modal tb-trigger-processed tb-modal-trigger-processed"
                    data-target="#jquery_ajax_load_inner" data-toggle="modal">
                    <img typeof="foaf:Image" class="img-responsive" src="{{ currentPartner.screenShot[2] }}"
                      alt="" /></a>
                </div>
              </div>
            </div>
            <div class="field field-name-field-apple-app-store-link field-type-link-field field-label-hidden">
              <div class="field-items">
                <div class="field-item even">
                  <a href="{{ currentPartner.appStoreLink.apple }}" target="_blank">Apple App Store Link</a>
                </div>
              </div>
            </div>
            <div class="field field-name-field-google-play-store-link field-type-link-field field-label-hidden"
              *ngIf="currentPartner.appStoreLink.google">
              <div class="field-items">
                <div class="field-item even">
                  <a href="{{ currentPartner.appStoreLink.apple }}" target="_blank">Google Play Store Link</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="fullImageUrl" id="jquery_ajax_load_inner" class="modal modal-gallery-app-inner in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block;">
  <div class="ctools-modal-dialog modal-dialog node-type-app">
    <div class="modal-content">
      <div class="modal-header"><button type="button" (click)="closeFullImageModal()" class="close" data-dismiss="modal" aria-hidden="true">×</button></div>
      <div class="modal-body"><div id="file-131" class="file file-image file-image-png">
  
          <h2 class="element-invisible"><a href="/file/131">1bios-screenshot-1_dexcom.png</a></h2>
      
    
    <div class="content">
      <img typeof="foaf:Image" class="img-responsive" [src]="fullImageUrl" alt="">  </div>
  
    
  </div>
  </div>
    </div>
  </div>
  </div>
<div *ngIf="showBackdrop" class="modal-backdrop fade in"></div>