import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'developer-portal-ui';
  isHomeScreen = true;

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe(() => {
        // if (confirm("New version available. Load New Version?")) {

        window.location.reload();
        // }
      });
    }

    this.router.events.subscribe(() => {
      this.isHomeScreen = this.router.url === '/home' || this.router.url === '/';
    });
    this.authService.autoLogin();
  }

  toggleLogIn(event: MouseEvent) {
    const dialog = document.getElementById('login') as HTMLInputElement;
    if (dialog.style.display === 'block') {
      if (!$(event.target as HTMLDivElement).closest('#login .modal-content').length) {
        dialog.style.display = '';
        dialog.classList.remove('in');
      }
    } else {
      dialog.style.display = 'block';
      dialog.classList.add('in');
    }
  }

  onActivate(event: any) {
    window.scroll(0, 0);
  }
}
